/* helper
----------------------------------------------------------------------------------------------*/
@import "../milligram/Color"; // import your general color from milligram
@import "../milligram/Variables"; // import your general color from milligram

$border-solid: 2px solid #d5d5d5;
$border-dashed: 2px dashed #d5d5d5;
$border-radius: 40px;

/* general styling
----------------------------------------------------------------------------------------------*/
html {
  overflow-x: hidden;
}

.space-top {
  padding-top: 100px;
}

.middle {
  margin-top: 80px;
  position: relative;
  overflow: hidden;

  .bg-top,
  .bg-mid,
  .bg-bottom {
    position: absolute;
    z-index: -1;
  }

  .bg-top {
    right: 0;
    top: 48px;
  }

  .bg-mid {
    width: auto;
    height: 100%;
    left: calc((100% - 970px) / 2);
    margin-left: -235px;
    top: 287px;

    > img {
      display: block;
      // height: 100%;
      width: 100%;
      // object-fit: cover;
      // object-position: center;
    }

    &::before {
      position: absolute;
      width: 100%;
      height: 1px;
      border-bottom: 2px solid $color-neutral-600;
      content: "";
      top: 0;
      left: -100%;
    }

    &::after {
      position: absolute;
      width: 100%;
      height: 1px;
      border-bottom: 2px solid $color-neutral-600;
      content: "";
      top: 1043px;
      right: -100%;
    }
  }

  .bg-bottom {
    left: 0;
    top: 1089px;
  }
}

.content-pages {
  > img {
    border-radius: 24px;
    height: auto;
    margin: 0 0 48px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  ul,
  ol,
  p {
    color: $color-neutral-900;
  }
  p{
    >img{
      width: auto;
      border-radius: 0;
    }
  }
  figure{
    &.image{
      display: flex;
      justify-content: center;
      
      &.image-style-side{
        justify-content: flex-end;
      }
      >img{
        width: auto;
        border-radius: 0;
      }
    }
  }
}

.top-title {
  h2 {
    color: $color-secondary;
  }

  h4 {
    color: $color-neutral-900;
  }

  .link-arrow,
  h2,
  h4 {
    margin-bottom: 32px;
  }
}

.box-button {
  display: flex;
}

.box-share {
  display: flex;
  align-items: center;
  margin-top: 22px;

  span {
    margin-right: 15px;
    font-weight: 700;
    color: $color-neutral-900;
  }

  div {
    display: flex;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
    }
  }
}

.load-more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;

  .button,
  .button-outline {
    margin-bottom: 0;
  }
}

.lang-header {
  > select {
    background: none;
  }
}

.line-point {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: auto;
  background: $color-initial;
  border: 2px solid #d5d5d5;
  border-radius: 16px;

  .circle-point {
    display: block;
    position: relative;
    width: 8px;
    height: 8px;
    background-color: #c4c4c4;
    border-radius: 8px;
  }
}

.motorcycle {
  position: relative;

  .motorcycle-wheel-front {
    position: absolute;
    bottom: -12px;
    left: 5px;
  }

  .motorcycle-wheel-back {
    position: absolute;
    bottom: -12px;
    right: 0px;
  }
}

.car {
  position: relative;

  .car-wheel-front {
    position: absolute;
    bottom: -42px;
    right: 30px;
  }

  .car-wheel-back {
    position: absolute;
    bottom: -42px;
    left: 42px;
  }
}

.truck {
  position: relative;

  .truck-wheel-front {
    position: absolute;
    bottom: -20px;
    right: 80px;
  }

  .truck-wheel-middle {
    position: absolute;
    bottom: -20px;
    left: 240px;
  }

  .truck-wheel-back {
    position: absolute;
    bottom: -20px;
    left: 105px;
  }
}

.bg-line-detail {
  position: absolute;
  top: 375px;
  bottom: 160px;
  width: 100%;

  .line-solid-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 63px;
    height: 72px;
    border-top: 2px solid #d5d5d5;
    border-right: 2px solid #d5d5d5;
    border-top-right-radius: 40px;

    .line-point {
      bottom: 0;
      right: -9px;
    }
  }

  .line-dashed-1 {
    position: absolute;
    top: 71px;
    left: 61px;
    width: calc(50% - 63px);
    height: 100px;
    border-bottom: 2px dashed #d5d5d5;
    border-left: 2px dashed #d5d5d5;
    border-bottom-left-radius: 40px;
  }

  .line-dashed-2 {
    position: absolute;
    top: 171px;
    bottom: 163px;
    right: 61px;
    width: calc(50% - 63px);
    border-top: 2px dashed #d5d5d5;
    border-right: 2px dashed #d5d5d5;
    border-top-right-radius: 40px;
  }

  .line-solid-2 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 63px;
    height: 163px;
    border-bottom: 2px solid #d5d5d5;
    border-left: 2px solid #d5d5d5;
    border-bottom-left-radius: 40px;

    .line-point {
      top: 0;
      left: -9px;
    }
  }
}

.bg-line-related {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;

  .line-solid-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 63px;
    height: 71px;
    border-top: $border-solid;
    border-right: $border-solid;
    border-top-right-radius: $border-radius;
  }

  .line-dashed-1 {
    position: absolute;
    top: 71px;
    left: 61px;
    width: calc(50% - 63px);
    height: 101px;
    border-bottom: 2px dashed #d5d5d5;
    border-left: 2px dashed #d5d5d5;
    border-bottom-left-radius: 40px;

    .line-point {
      top: 0;
      left: -9px;
    }
  }

  .line-solid-2 {
    position: absolute;
    top: 170px;
    right: 61px;
    height: 71px;
    width: calc(50% - 63px);
    border-top: 2px solid #d5d5d5;
    border-right: 2px solid #d5d5d5;
    border-top-right-radius: 40px;
  }

  .line-dashed-2 {
    position: absolute;
    top: 242px;
    right: 0;
    width: 63px;
    height: 119px;
    border-bottom: 2px dashed #d5d5d5;
    border-left: 2px dashed #d5d5d5;
    border-bottom-left-radius: 40px;

    .line-point {
      top: 0;
      left: -9px;
    }
  }

  .line-dashed-3 {
    position: absolute;
    top: 170px;
    right: 0;
    width: 50%;
    border-top: 2px dashed #d5d5d5;
  }
}

.field {
  .autocomplete-box {
    position: absolute;
    left: 48px;
    right: 0;
    padding: 0;
    opacity: 0;
    max-height: 280px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 1px 5px rgb(0 0 0 / 10%);
    pointer-events: none;
    overflow-y: auto;
    z-index: 100;

    li {
      text-align: left;
      list-style: none;
      padding: 8px 12px;
      display: none;
      width: 100%;
      cursor: default;
      border-radius: 3px;

      &:hover {
        background: #efefef;
      }
    }
  }

  &.active {
    .autocomplete-box {
      padding: 10px 8px;
      opacity: 1;
      pointer-events: auto;

      li {
        display: block;
      }
    }
  }
}

// button[embeddedService-chatHeader_chatHeader] {
//   width: 32px;
//   padding-right: 0;
//   padding-left: 0;
// }
// h2[embeddedService-chatHeader_chatHeader] {
//   color: #fff;
// }


//-------------------- live chat styling ----------------------
.sidebarHeader {
  position: unset !important;
  h2 {
    color: #FFF !important;
  }
}
.minimizeButton {
  padding: 0 !important;
}
.closeButton {
  padding: 4px 0 0 0 !important;
}
.grid-container {
  width: 100% !important;
}
.embeddedServiceSidebarForm.buttonWrapper {
  padding: 30px 14px 24px !important;
}
.embeddedServiceSidebarButton .label,
.embeddedServiceSidebarButton:disabled .label {
  line-height: 1 !important;
}
.FirstName,
.LastName,
.Email,
.Phone {
  input {
    height: 37px !important;
    padding: 6px 10px !important;
  }
}
//-------------------- live chat styling ----------------------


@media all and (max-width: 1300px) {
  .bg-line-detail {
    top: 320px;
    bottom: 160px;

    .line-solid-1 {
      left: -43px;
    }

    .line-dashed-1 {
      position: absolute;
      top: 71px;
      left: 18px;
      width: calc(50% - 19px);
      height: 100px;
      border-bottom: 2px dashed #d5d5d5;
      border-left: 2px dashed #d5d5d5;
      border-bottom-left-radius: 40px;
    }

    .line-dashed-2 {
      right: 0;
      width: 50%;
      border-top: 2px dashed #d5d5d5;
      border-right: none;
      border-top-right-radius: 0;
    }

    .line-solid-2 {
      display: none;
    }
  }

  .bg-line-related {
    .line-solid-1 {
      left: -43px;
    }

    .line-dashed-1 {
      position: absolute;
      top: 71px;
      left: 18px;
      width: calc(50% - 19px);
      height: 100px;
      border-bottom: 2px dashed #d5d5d5;
      border-left: 2px dashed #d5d5d5;
      border-bottom-left-radius: 40px;
    }

    .line-dashed-2,
    .line-dashed-3 {
      top: 169px;
      right: 0;
      width: 50%;
      height: 0;
      border-top: 2px dashed #d5d5d5;
      border-bottom: none;
      border-right: none;
      border-left: none;
      border-radius: 0;

      .line-point {
        display: none;
      }
    }

    .line-solid-2 {
      display: none;
    }
  }
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .bg-line-detail {
    top: 320px;
    bottom: 160px;

    .line-solid-1 {
      left: -43px;
    }

    .line-dashed-1 {
      position: absolute;
      top: 71px;
      left: 18px;
      width: calc(50% - 19px);
      height: 100px;
      border-bottom: 2px dashed #d5d5d5;
      border-left: 2px dashed #d5d5d5;
      border-bottom-left-radius: 40px;
    }

    .line-dashed-2 {
      right: 0;
      width: 50%;
      border-top: 2px dashed #d5d5d5;
      border-right: none;
      border-top-right-radius: 0;
    }

    .line-solid-2 {
      display: none;
    }
  }

  .bg-line-related {
    .line-solid-1 {
      left: -43px;
    }

    .line-dashed-1 {
      position: absolute;
      top: 71px;
      left: 18px;
      width: calc(50% - 19px);
      height: 100px;
      border-bottom: 2px dashed #d5d5d5;
      border-left: 2px dashed #d5d5d5;
      border-bottom-left-radius: 40px;
    }

    .line-dashed-2,
    .line-dashed-3 {
      top: 169px;
      right: 0;
      width: 50%;
      height: 0;
      border-top: 2px dashed #d5d5d5;
      border-bottom: none;
      border-right: none;
      border-left: none;
      border-radius: 0;

      .line-point {
        display: none;
      }
    }

    .line-solid-2 {
      display: none;
    }
  }

  .load-more {
    margin-top: 32px;
  }

  .car {
    transform: scale(0.8);
    transform-origin: 0 0;
  }
  .truck {
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .bg-line-detail,
  .bg-line-related {
    display: none;
  }

  .load-more {
    margin-top: 20px;
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 766px) {
  .load-more {
    margin-top: 40px;
  }
}
