/* header
----------------------------------------------------------------------------------------------*/
.navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  max-height: 80px;
  background: $color-initial;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
  transition: all 0.3s ease-out;
  z-index: 99;

  > .wrapper {
    display: flex;
    flex-wrap: inherit;
    align-items: stretch;
    justify-content: space-between;
  }
}

.navbar-logo {
  padding: 0;
  margin: 0;
  text-decoration: none;
  white-space: nowrap;

  a {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.navbar-collapse {
  position: relative;
}

.navbar-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-family: "Heebo";
  margin: 0;

  .nav-item {
    margin: 0;

    &:hover,
    &.active {
      .nav-link,
      .nav-search,
      .nav-lang {
        color: $color-shades-dark-red;
      }

      .nav-link {
        &::after {
          width: 50%;
        }
      }
    }
  }

  .nav-link,
  .nav-search,
  .nav-lang {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 24px;
    padding: 28px 15px;
    transition: all 0.15s ease-in-out;
  }

  .nav-link {
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    color: $color-neutral-900;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 4px;
      background: $color-primary;
      transform: translateX(-50%);
      transition: all 0.25s ease-out;
    }
  }

  .nav-arrow {
    display: none;
  }

  .nav-search {
    padding: 0 15px;
    font-size: 24px;
    line-height: 0;
    color: $color-neutral-900;
    height: auto;
    margin: 0;
    background-color: transparent;
    border: none;
  }

  .nav-lang {
    font-size: 11px;
    color: $color-neutral-700;
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;

    i {
      font-size: 24px;
      margin-right: 2px;
    }
  }
}

@media (hover: hover) {
  .navbar-nav {
    .nav-item {
      &.dropdown {
        &:hover {
          .dropdown-menu,
          .dropdown-menu-lg {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

.dropdown {
  .dropdown-menu {
    position: absolute;
    top: 60px;
    right: 0;
    width: auto;
    padding: 8px 0px;
    background-color: $color-initial;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-out;

    ul,
    li {
      margin: 0;
    }
  }

  .dropdown-menu-lg {
    font-family: "Outfit";
    position: fixed;
    top: 0;
    left: 50%;
    width: 100vw;
    padding: 112px 0px 64px;
    transform: translateX(-50%);
    background-color: $color-initial;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-out;

    .bg-line {
      position: absolute;
      top: 112px;
      bottom: 0;
      right: 0;
      width: 96px;

      .line-solid {
        position: absolute;
        top: 0;
        right: 0;
        width: 96px;
        height: 71px;
        border-top: 2px solid #d5d5d5;
        border-left: 2px solid #d5d5d5;
        border-top-left-radius: 40px;
      }

      .line-dashed {
        position: absolute;
        top: 71px;
        bottom: 0;
        right: 0;
        width: 96px;
        border-left: 2px dashed #d5d5d5;
      }

      .line-point {
        top: 71px;
        left: -7px;
        margin: 0;
      }
    }

    .row {
      margin-left: -50px;

      .column {
        padding: 0 50px;

        *:last-child {
          margin-bottom: 0;
        }
      }
    }

    .nav-link-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: $color-secondary;
      margin-bottom: 16px;
    }

    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 24px;
    }
  }

  .dropdown-item {
    display: block;

    .dropdown-link {
      font-size: 14px;
      color: $color-neutral-900;
      padding: 8px 24px;
      transition: all 0.15s ease-in-out;
    }

    &:hover {
      .dropdown-link {
        color: $color-shades-dark-red;
      }
    }
    &.active {
      .dropdown-link {
        color: $color-shades-dark-red;
      }
    }
  }
}

.navbar-search {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: $color-initial;
  z-index: 100;

  .input-group {
    font-family: "Outfit";
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;

    i {
      font-size: 24px;
    }

    .ic-search {
      color: $color-primary;
    }

    input {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      margin: 0;
      padding: 18px;
      border: none;

      &::placeholder {
        color: $color-neutral-600;
      }
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $color-neutral-700;
      margin: 0;
      background: transparent;
      border: none;
      padding: 0;
      margin-left: 28px;

      i {
        margin-left: 8px;
      }
    }
  }
}

.navbar-other {
  .nav-search {
    display: none;
  }
}

.navbar-lang {
  display: none;
}

.navbar-toggle {
  display: none;
}

.floating-chat {
  position: fixed;
  width: 109px;
  height: 129px;
  right: 24px;
  bottom: 60px;
  z-index: 89;
  transition: all 0.2s ease-out;
  display: none;
}

.cookies-box {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: rgba(20, 36, 94, 0.9);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  padding: 16px 0;
  z-index: 999;
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      color: $color-initial;
      margin-bottom: 0;
      a {
        color: $color-initial;
        font-weight: 700;
        text-decoration: underline;
      }
    }
    button {
      margin-bottom: 0;
      height: 40px;
      line-height: 38px;
      &:hover {
        background: $color-initial;
        border-color: $color-initial;
        color: $color-shades-blue;
      }
    }
  }
}

/* footer
----------------------------------------------------------------------------------------------*/
footer {
  box-shadow: inset 0px 1px 0px $color-neutral-400;

  h5 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 22px;
    margin-bottom: 16px;
    letter-spacing: -0.01em;
    color: $color-neutral-800;
  }

  .footer-top {
    padding: 97px 0 62px;

    .wrap-footer-top {
      display: flex;
      flex-wrap: wrap;

      &__left {
        width: 235px;

        h5 {
          margin-bottom: 24px;
        }

        .box-cs {
          margin-bottom: 24px;

          p {
            margin-bottom: 8px;
            line-height: normal;
            line-height: 0.6;

            a {
              display: inline-flex;
              align-items: center;
              color: $color-neutral-800;
              font-weight: 500;
              &:hover {
                color: $color-shades-dark-red;
              }

              i {
                width: 32px;
                height: 32px;
                margin-right: 12px;

                img {
                  width: 100%;
                }
              }
            }
          }
        }

        .box-socmed {
          display: flex;
          align-items: center;

          a {
            width: 44px;
            height: 44px;
            margin-right: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:last-child {
              margin-right: 0;
            }

            img {
              transition: all 0.2s ease-out;
            }

            &:hover {
              img {
                transform: scale(1.1);
              }
            }
          }
        }
      }

      &__right {
        width: calc(100% - 235px);
        padding-left: 202px;

        .box-nav {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -40px 53px;

          &__column {
            width: calc(100% / 3 - 80px);
            margin: 0 40px;
            align-self: self-start;
            margin-bottom: 28px;
            .nav-foot {
              margin-bottom: 40px;

              ul {
                margin: 0;

                li {
                  line-height: normal;
                  margin: 0;

                  a {
                    display: inline-block;
                    padding: 6px 0;
                    color: $color-neutral-800;
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: 15px;
                    transition: all 0.2s ease-out;

                    &:hover {
                      color: $color-primary;
                    }
                  }

                  &:first-child {
                    a {
                      padding-top: 0;
                    }
                  }

                  &:last-child {
                    a {
                      padding-bottom: 0;
                    }
                  }
                }
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
        .box-app {
          h6 {
            margin-bottom: 17px;
          }

          &__list {
            display: flex;

            a {
              margin-right: 12px;
              display: inline-flex;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      &__bottom {
        padding-left: calc(235px + 202px);
      }
    }
  }

  .footer-bottom {
    padding: 16px 0 13px;
    background: #f8f8f8;

    .wrap-footer-bottom {
      display: flex;
      justify-content: space-between;

      &__left {
        .copyright {
          margin: 0;
          line-height: normal;
          font-size: 0.75rem;
          font-weight: 400;
          color: $color-neutral-800;

          a {
            color: $color-neutral-800;
            transition: all 0.2s ease-out;

            &:hover {
              color: $color-primary;
            }
          }
        }
      }

      &__right {
        .nav-static {
          display: flex;
          margin-bottom: 4px;

          a {
            font-size: 0.75rem;
            margin-right: 24px;
            color: $color-neutral-800;
            font-weight: 600;
            transition: all 0.2s ease-out;
            white-space: nowrap;

            &:last-child {
              margin-right: 0;
            }

            &:hover {
              color: $color-primary;
            }
          }
        }
      }
    }
  }
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .navbar {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 15px;
      box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
    }
  }

  .navbar-nav .nav-link::after {
    display: none;
  }

  .offcanvas {
    .offcanvas-overlay {
      display: none;
      position: fixed;
      top: 79px;
      left: 50%;
      width: 100vw;
      height: 100vh;
      background: rgb(0 0 0 / 50%);
      transform: translateX(-50%);
    }

    .offcanvas-body {
      display: none;
      position: fixed;
      top: 79px;
      right: 0;
      width: 560px;
      height: calc(100vh - 79px);
      background: $color-initial;
      padding: 54px 48px 54px 54px;
      transform: translateX(100%);
      transition: transform 0.5s ease;
      overflow-y: auto;

      .navbar-nav {
        flex-direction: column;
        align-items: flex-start;

        .nav-item {
          position: relative;
          width: 100%;

          &:hover {
            .nav-link {
              &::after {
                width: 0px !important;
              }
            }
          }

          &.show {
            .nav-link,
            .nav-arrow {
              color: $color-primary;
            }

            .nav-arrow {
              transform: rotate(-180deg);
            }
          }
        }

        .nav-link {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          font-weight: 600;
          font-size: 16px;
          color: $color-neutral-900;
          padding: 16px 0px;
        }

        .nav-arrow {
          position: absolute;
          display: flex;
          align-items: center;
          top: 16px;
          right: 0;
          font-size: 24px;
          cursor: pointer;
          transition: all 0.15s ease-out;
        }

        .nav-search {
          display: none;
        }
      }

      .dropdown {
        .dropdown-menu-lg {
          display: none;
          position: relative;
          width: 100%;
          padding: 0;
          margin-bottom: 10px;
          box-shadow: none;
          z-index: 99;
          visibility: visible;
          opacity: 1;
          transition: unset;

          .wrapper {
            max-width: 100%;
            margin: 0;

            .row {
              flex-direction: column;
              width: 100%;
              margin: 0;

              .column {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;
              }
            }
          }

          .bg-line,
          p,
          .button {
            display: none;
          }

          .nav-link-title {
            display: block;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            padding: 6px 12px;
            margin-bottom: 0;
          }
        }
      }
    }

    &.show {
      .offcanvas-body {
        transform: translateX(0);
      }
    }
  }

  .navbar-search {
    display: block;
    position: relative;
    left: 0;
    right: 0;

    .input-group {
      border-bottom: 1px solid rgb(0 0 0 / 10%);

      .ic-search {
        color: $color-neutral-900;
        order: 2;
      }

      input {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        height: 52px;
        padding: 13px;
        padding-left: 0;
        border-radius: 0;
        order: 1;
      }
      .nav-close-search {
        display: none;
      }
    }
  }

  .navbar-other {
    display: flex;
    flex-direction: row;
    align-items: center;

    .nav-search {
      display: block;
    }

    .navbar-nav {
      display: none;
    }
  }

  .navbar-lang {
    display: flex;
    margin-top: 16px;

    .nav-item {
      padding: 16px 0px;

      > a {
        font-weight: 600;
        font-size: 16px;
        color: $color-neutral-900;
        text-transform: uppercase;

        &.active {
          color: $color-primary;
        }
      }

      &::after {
        content: " / ";
        white-space: pre;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .navbar-toggle {
    display: flex;
    align-items: center;
    margin-left: 24px;

    .nav-toggler {
      margin: 0;
      background: none;
      border: none;
      padding: 0;

      span {
        display: block;
        width: 20px;
        height: 3px;
        margin-bottom: 4px;
        background-color: $color-secondary;
        z-index: 9999;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  footer {
    .footer-top {
      .wrap-footer-top {
        &__right {
          padding-left: 150px;
        }

        &__bottom {
          padding-left: calc(235px + 150px);
        }
      }
    }

    .footer-bottom {
      .wrap-footer-bottom {
        display: flex;

        &__left {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .copyright {
            margin: 0;
            line-height: normal;
            font-size: 0.75rem;
            color: $color-neutral-800;

            a {
              color: $color-neutral-800;
              transition: all 0.2s ease-out;

              &:hover {
                color: $color-primary;
              }
            }
          }
        }

        &__right {
          .nav-static {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .floating-chat {
    position: fixed;
    width: 90px;
    height: 93px;
    right: 30px;
    bottom: 143px;
    z-index: 89;
    transition: all 0.2s ease-out;
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .navbar-logo {
    img {
      width: auto;
      height: 40px;
    }
  }

  .offcanvas {
    .offcanvas-body {
      width: 423px;
    }
  }

  .navbar-search {
    .input-group {
      span {
        display: none;
      }
    }
  }

  .cookies-box {
    padding: 24px 0;
    .wrapper {
      max-width: calc(100% - 74px);
      flex-direction: column;
      p {
        margin-bottom: 24px;
        text-align: center;
      }
    }
  }

  footer {
    .footer-top {
      .wrap-footer-top {
        &__right {
          padding-left: 70px;

          .box-nav {
            &__column {
              width: calc(100% / 2 - 40px);
              margin: 0 20px 32px;

              &:nth-child(3) {
                // display: none;
              }
              &.others {
                order: 10;
              }
            }
          }
        }

        &__bottom {
          padding-left: 0;
        }
      }
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 766px) {
  .navbar {
    height: 72px;
    max-height: 72px;
  }

  .navbar-logo {
    img {
      width: auto;
      height: 32px;
    }
  }

  .offcanvas {
    .offcanvas-body {
      width: 100%;
      top: 71px;
      height: calc(100vh - 71px);
      padding: 16px 24px;
    }
  }

  .navbar-toggle {
    margin-left: 0;
  }

  footer {
    .footer-top {
      padding: 50px 0 62px;
      .wrap-footer-top {
        flex-direction: column;
        gap: 40px 0px;

        &__right {
          width: 100%;
          padding-left: 0;

          .box-nav {
            flex-direction: row;
            margin: 0;

            &__column {
              flex: 0 0 50%;
              width: 50%;
              margin: 0 0 28px;
            }
          }
        }

        &__bottom {
          .box-app {
            h6 {
              font-weight: 400;
              font-size: 1rem;

              b {
                display: block;
              }
            }

            &__list {
              display: flex;
              flex-direction: column;

              img {
                width: 194px;
              }
            }
          }
        }
      }
    }

    .footer-bottom {
      padding: 26px 0;

      .wrap-footer-bottom {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        &__left {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;

          .copyright {
            flex: 0 0 100%;
            order: 1;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  .floating-chat {
    right: 14px;
    bottom: 180px;
  }

  .cookies-box {
    .wrapper {
      max-width: calc(100% - 48px);
      p {
        font-size: .875rem;
        line-height: 1.6;
      }
    }
  }
}
